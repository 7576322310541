<template>
      <div class="user_sidebar">
        <ul class="list-group" id="list_group">
          <li class="text-center">
              <i class="bi bi-person-bounding-box customer_icon"></i>
              <p class="customer_name mt-3"> {{ user.name ? user.name : 'Set Name' }} </p>
              <p class="customer_name"> {{ user.mobile_no }} </p>
          </li>
        <li  >
          <router-link :to="{ name: 'UserDashboard' }"
            ><i class="bi bi-list"></i> Dashboard
          </router-link>
        </li>
        <li >
          <router-link :to="{ name: 'UserProfile' }"
            > <i class="bi bi-person-circle"></i> Profile
          </router-link>
        </li>
        <li >
          <router-link :to="{ name: 'UserProfileEdit' }">
            <i class="bi bi-pencil-square"></i> Edit Profile</router-link >
        </li>

        <li >
          <router-link :to="{ name: 'PasswordEdit' }">
            <i class="bi bi-key-fill"></i> Change Password</router-link
          >
        </li>
          <li  >
          <router-link :to="{ name: 'setNewPassword' }">
            <i class="bi bi-key-fill"></i> Set New Password</router-link
          >
        </li>
         <li  >
          <a href=""  @click="Logout" >
            <i class="bi bi-power"></i> Logout</a>
        </li>
      </ul>
      </div>
</template>


<script>
export default {
    created(){
      this.$store.dispatch("user");
    },
    methods:{

      Logout() {
         localStorage.removeItem("user_token");
         localStorage.removeItem("mDashboardReloaded");
         location.reload();
         this.$router.push({name:'Home'}).go();

       },
    },
    computed :{
       user(){
        return this.$store.getters.user ;
       }
    },
}



</script>

<style scoped>

.user_sidebar{

    margin-top:25px;
    margin-bottom:25px;
    min-height: 350px;
    background: #F9DFC0;
 }
 .list-group{
    padding-top:25px;
 }

 ul li a{
     color: #fff;
    background-color: #dd8a24;
    padding: 10px;
    float: left;
    display: block;
    width: 100%;
    margin-top:5px;
 }
 .router-link-exact-active {
   background-color: #ff4d0c !important;
 }

 i {
   font-size:14px;
 }

.customer_icon{
   font-size:80px;
}

 .customer_name{
   line-height: 11px;
   font-size:18px;
   color:#000;
   text-transform: uppercase;
 }


</style>