<template>

      <div class="container">
       <div style="min-height:400px;" class="row bg-white">
           <div class="col-md-2 col-sm-2 ">
             <sidebar/>
           </div>

           <div class="col-md-10 col-sm-10 ">
             <div class="table_overflow">
                  <h4 class="heading"></h4>
                   <br />
                <table class="table table-hover table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Product</th>
                      <th>Code</th>
                      <th>Qty</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody class="table-responsive">
                    <tr v-for="(item, index) in items" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ item.product.name }}
                        <strong v-if="item.attribute"
                          >-{{ item.attribute.name }}[{{
                            item.variant.name
                          }}]</strong
                        >
                      </td>
                      <td>{{ item.product.product_code }}</td>
                      <td>{{ item.qty }}</td>
                      <td>{{ item.price }}</td>
                      <td>{{ item.qty * item.price }}</td>
                    </tr>

                    <tr>
                      <td colspan="4"></td>
                      <td>
                        <b>Sub Total</b>
                      </td>
                      <td>
                        <b>{{ order.total }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                      <td>
                        <b>Discount</b>
                      </td>
                      <td>
                        <b>{{ order.discount }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                      <td>
                        <b>Paid</b>
                      </td>
                      <td>
                        <b>{{ order.paid }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4"></td>
                      <td>
                        <b>Shiiping_cost</b>
                      </td>
                      <td>
                        <b>{{ order.shipping_cost }}</b>
                      </td>
                    </tr>

                    <tr>
                      <td colspan="4"></td>
                      <td>
                        <b>Amount Due</b>
                      </td>
                      <td>
                        <b>{{
                          parseInt(order.total) -
                          (parseInt(order.discount) + parseInt(order.paid)) +
                          parseInt(order.shipping_cost)
                        }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
            <div class="row bottomBtn">

                <button style="width: 60px; margin-left: 10px;" class="btn btn-warning back" @click="back">
                   <i class="bi bi-arrow-left-circle"></i>
                </button>
            </div>

           </div>
          </div>
          </div>



      </div>

</template>

<script>
import sidebar from "../../components/Sidebar.vue";
export default {
  created() {
    this.getDetails();
  },

  data() {
    return {
      order: "",
      items: "",
      loading: true,
    };
  },

  methods: {
    back(){
        this.$router.push({ name : 'UserDashboard'});
    },

   print(order_id) {

     window.open('/_public/customer/order/invoice/print/'+order_id,'_blank')

    },
    getDetails() {
      this.axios
        .get("user/order/details/" + this.$route.params.id)
        .then((resp) => {
          console.log(resp);
          if (resp.data.status == "SUCCESS") {
            this.order = resp.data.order;
            this.items = resp.data.items;
            this.loading = false;
          }
        })

    },
  },

   components:{
     sidebar
  },

};

</script>


<style scoped>

@media print {
  .navbar {
    display: none;
  }
  .col-lg-2 {
    width: 20% !important;
  }
  .invoice-header {
    display: block;
  }
  .box {
    border-top: none;
  }
  footer {
    border-top: none !important;
  }


}

.table_overflow {
  overflow-x: auto;
}

@media screen  and (max-width:650px) {

    .bottomBtn{
      margin-left: 20px;
      margin-bottom: 20px;
    }



}




</style>
